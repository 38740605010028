import { decode } from 'url-encode-decode';

import { HashType } from '../../types';

export function parseSearchString(searchString: string): HashType {
  const splits = decode(searchString).substring(1).split('&');

  return splits.reduce((result: HashType, current: string) => {
    const QUERY_DELIMITER = '='; /* Matches for "my_key=my-value" */
    const [key, value] = current.split(QUERY_DELIMITER);

    result[key] = value;

    return result;
  }, {});
}

export const logger = {
  info(...args: unknown[]) {
    console.info(
      '%c@blendle/analytics',
      'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
      ...args,
    );
  },
};
