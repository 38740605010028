import Cookies from 'js-cookie';
import { v4 as uuidV4 } from 'uuid';

import { ConfigEnvironment } from 'types';

const COOKIE_KEY = 'uuid';

export function getSessionId(config: ConfigEnvironment): string {
  let uuid = Cookies.get(COOKIE_KEY);

  if (!uuid) {
    uuid = uuidV4();
    Cookies.set(COOKIE_KEY, uuid, { domain: config.cookieDomain, path: '/' });
  }

  return uuid;
}
