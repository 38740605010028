export const config = {
  development: {
    apiKey: 'tUukN7PoL2qsrqC2vh8zLiBydvzcMUE2',
    cookieDomain: '',
  },
  approval: {
    apiKey: 'lAk6BATXdmNFXC4QFhDwkdXGR0ZeOY74',
    cookieDomain: '',
  },
  test: {
    hostUrl: '',
    apiKey: '',
    cookieDomain: '',
  },
  production: {
    apiKey: 'rsLN7bLxVXIl5rvaUJOoZpQz2NEylZqA',
    cookieDomain: '.blendle.com',
  },
};
