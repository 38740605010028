import { config } from 'config';
import { logger } from 'utils/internal';
import { getSessionId as getSessionIdInternal } from 'utils/getSessionId';
import { getUtmParams } from 'utils/getUtmParams';
import {
  PageParams,
  AnalyticsFactory,
  IdentifyParams,
  AnalyticsInstance,
  TrackParams,
} from 'types';

export function Analytics({ environment }: AnalyticsFactory): AnalyticsInstance {
  const currentConfig = config[environment];

  const isTestEnv = environment === 'test';

  const log = (...args: unknown[]) => {
    if (environment !== 'production') {
      logger.info(args);
    }
  };

  const init = () => {
    if (isTestEnv) return;

    if (!window.analytics.initialized) {
      window.analytics.load(currentConfig.apiKey);
    }
  };

  const track = ({ eventName, properties, options }: TrackParams) => {
    log('Segment Track', eventName, properties, options);

    if (isTestEnv) return;

    window.analytics.track(eventName, properties, options);
  };

  const page = ({ pageName, properties = {}, options }: PageParams) => {
    log('Segment Page', pageName, properties, options);

    if (isTestEnv) return;

    window.analytics.page(pageName, properties, options);
  };

  const identify = ({ userId, options }: IdentifyParams) => {
    log('Segment Identify', userId, options);

    if (isTestEnv) return;

    const traits = {}; // Traits will send in the backend
    window.analytics.identify(userId, traits, options);
  };

  const reset = () => {
    log('Segment Reset');

    if (isTestEnv) return;

    window.analytics.reset();
  };

  const getSessionId = () => {
    return getSessionIdInternal(currentConfig);
  };

  return {
    getSessionId,
    getUtmParams,
    identify,
    init,
    page,
    reset,
    track,
  };
}
