import { HashType } from 'types';
import { parseSearchString } from 'utils/internal';

export function getUtmParams(searchString?: string) {
  const searchStringToBeUsed = searchString || window.location.search;

  const utmParams: Partial<HashType> = {};

  if (!searchStringToBeUsed) {
    return utmParams;
  }

  const queryObj = parseSearchString(searchStringToBeUsed);

  if (queryObj.utm_medium || queryObj.medium) {
    utmParams.medium = queryObj.utm_medium || queryObj.medium;
  }

  if (queryObj.utm_source || queryObj.source) {
    utmParams.source = queryObj.utm_source || queryObj.source;
  }

  if (queryObj.utm_term || queryObj.term) {
    utmParams.term = queryObj.utm_term || queryObj.term;
  }

  if (queryObj.utm_content || queryObj.content) {
    utmParams.content = queryObj.utm_content || queryObj.content;
  }

  if (queryObj.utm_campaign || queryObj.campaign) {
    utmParams.campaign = queryObj.utm_campaign || queryObj.campaign;
  }

  if (queryObj.adid) {
    utmParams.adid = queryObj.adid;
  }

  return utmParams;
}
